import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {IPropsInjectedByViewerScript, IWishlistStyleParams, IProduct} from '../types/app-types';
import {IControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {MultilingualService} from '@wix/wixstores-client-core/dist/es/src/multilingualService/multilingualService';
import {
  APP_DEFINITION_ID,
  PageMap,
  ActionStatus,
  BiButtonActionType,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {WishlistService} from '../services/WishlistService';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';
import {
  ORIGIN,
  translationPath,
  PublicDataKeys,
  GalleryViewMode,
  FedopsEvent,
  WISHLIST_BI_APP_NAME,
} from '../constants';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {getDefaultStyleParams} from '../commons/getDefaultStyleParams';
import * as _ from 'lodash';
import {productsPerPage} from './utils';
import {
  PaginationTypeName,
  PaginationType,
  ProductsManifest,
} from '@wix/wixstores-client-gallery/dist/es/src/types/galleryTypes';
import {ILink} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/src/constants';
import {ITrackEventParams} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/AddToCartService';
import {actualPrice} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';

export class WishlistStore {
  private currentPage: number = 1;
  private multilingualService: MultilingualService;
  private products: IProduct[];
  private productsPerPage: number;
  private readonly fedopsLogger;
  private readonly productActions: ProductActions;
  private readonly wishlistService: WishlistService;
  private readonly addToCartService: AddToCartService;
  private sectionUrl: string;
  private shouldReportFedops: boolean = true;
  private translations;
  private homepageLink: ILink;
  private addedToCartStatus: {[p: string]: ActionStatus} = {};

  constructor(
    private publicData: IControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly compId: string,
    private readonly type: string,
    private styleParams: IWishlistStyleParams,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }

    this.wishlistService = new WishlistService(this.siteStore, this.externalId);
    this.productActions = new ProductActions(this.siteStore);
    this.addToCartService = new AddToCartService(this.siteStore, publicData);
    this.productsPerPage = productsPerPage(this.styleParams);
    this.handleCurrencyChange();
  }

  private handleCurrencyChange() {
    let currency = this.siteStore.location.query.currency;
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.siteStore.location.onChange(async () => {
      if (currency !== this.siteStore.location.query.currency) {
        currency = this.siteStore.location.query.currency;
        await this.updateComponent();
      }
    });
  }

  public async setInitialState(): Promise<void> {
    const [section, translations, appSettings, homepageLink] = await Promise.all([
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
      getTranslations(translationPath(this.siteStore.baseUrls.wishlistBaseUrl, this.siteStore.locale)),
      this.wishlistService.getAppSettings(),
      this.siteStore.getHomepageLink(),
    ]);

    this.sectionUrl = section.url;
    this.translations = translations;
    this.homepageLink = homepageLink;

    this.multilingualService = new MultilingualService(
      this.publicData.COMPONENT,
      appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    await this.updateComponent();

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  private async updateComponent() {
    const propsToInject = await this.getComputedProps();

    this.setProps(propsToInject);
  }

  private async getComputedProps(): Promise<IPropsInjectedByViewerScript> {
    const offset = (this.currentPage - 1) * this.productsPerPage;
    const limit = this.productsPerPage;
    const products = (this.products = await this.getProducts(limit, offset));
    const totalProducts = this.wishlistService.getTotalProducts();
    const hasMoreProducts = totalProducts - limit + offset > 0;

    return {
      ...this.getProductItemProps(),
      allowFreeProducts: this.addToCartService.allowFreeProducts,
      currentPage: this.currentPage,
      handlePagination: this.handlePagination.bind(this),
      handleLoadMore: this.handleLoadMore.apply(this),
      handleClickOnEmptyState: this.handleClickOnEmptyState.bind(this),
      gridType: this.styleParams.numbers.gallery_gridType,
      hasMoreProducts,
      isAutoGrid: true,
      isLiveSiteMode: true,
      isLoaded: this.wishlistService.isLoaded(),
      isMobile: this.siteStore.isMobile(),
      isRTL: this.siteStore.isRTL(),
      loadMoreType: this.styleParams.numbers.gallery_loadMoreProductsType,
      onAppLoaded: this.onAppLoaded.bind(this),
      productSize: this.styleParams.numbers.gallery_productSize,
      products,
      removeProduct: this.removeProduct.bind(this),
      signature: this.wishlistService.signature(),
      styleParams: this.styleParams,
      textsMap: this.getTextsMap(),
      totalProducts,
      homePageUrl: this.homepageLink.url,
      isEmptyState: totalProducts <= 0,
      paginationMode: this.getPaginationMode(),
      productsPerPage: this.productsPerPage,
    };
  }

  private async getProducts(limit: number, offset: number) {
    const viewMode =
      this.siteStore.isSiteMode() || this.siteStore.isPreviewMode()
        ? GalleryViewMode.LIVE_SITE
        : this.styleParams.numbers.gallery_editorViewMode;
    const products = await this.wishlistService.getProducts({
      limit,
      offset,
      viewMode,
      productsPerPage: this.productsPerPage,
    });
    if (viewMode === GalleryViewMode.EDITOR_DEMO_STATE) {
      products.forEach((p) => (p.name = this.translations['wishlist.demoProduct.title']));
    }
    return products;
  }

  private async removeProduct(productId: string) {
    const index = this.products.findIndex((p) => p.id === productId);
    const biEventData = this.createProductItemBiEventData(productId, index);
    this.fedopsLogger.interactionStarted(FedopsEvent.RemoveFromWishlist);
    this.reportToBI('clickRemoveFromWishlistSf', biEventData);

    this.products = this.products.filter((p) => p.id !== productId);
    await this.updatePartialComponent({products: this.products});
    await this.wishlistService
      .removeProduct(productId)
      .then(() => {
        this.fedopsLogger.interactionEnded(FedopsEvent.RemoveFromWishlist);
        this.reportToBI('productRemovedFromWishlistSf', biEventData);
      })
      .catch(this.reportError);
    await this.updateComponent();
  }

  private reportToBI(eventName, eventData): void {
    (this.siteStore.biLogger as {})[eventName](eventData);
  }

  private createProductItemBiEventData(productId: string, index: number) {
    const {ribbon, options, id, productType} = this.pickProduct(productId);

    return {
      hasOptions: options.length > 0,
      hasRibbon: !!ribbon,
      index,
      productId: id,
      productType,
      origin: ORIGIN,
    };
  }

  private updatePartialComponent(props: Partial<IPropsInjectedByViewerScript>): Promise<void> {
    this.setProps(props);
    return Promise.resolve();
  }

  public async updateState(
    newStyleParams: IWishlistStyleParams,
    newPublicData: IControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    const nextStyleParams = getStyleParamsWithDefaults(newStyleParams, () => {
      return getDefaultStyleParams(newStyleParams);
    });
    this.updatePublicData(newPublicData);
    this.multilingualService.setPublicData(this.publicData.COMPONENT);
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    this.styleParams = {...nextStyleParams};
    this.productsPerPage = productsPerPage(this.styleParams);
    this.setProps(await this.getComputedProps());
  }

  private updatePublicData(newPublicData: IControllerConfig['publicData']) {
    /* istanbul ignore next: hard to test it */
    this.publicData = _.merge(this.publicData, newPublicData);
  }

  private getProductItemProps() {
    return {
      addedToCartStatus: this.addedToCartStatus,
      experiments: {},
      handleAddToCart: this.handleAddToCart.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      openQuickView: this.handleOpenQuickView.bind(this),
      productsManifest: this.productsManifest,
      shouldShowAddToCartSuccessAnimation: true,
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
    };
  }

  private readonly updateAddToCartStatus = (productId: string, status: ActionStatus) => {
    this.addedToCartStatus = {
      ...this.addedToCartStatus,
      [productId]: status,
    };

    return this.updatePartialComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private getTextsMap(): IPropsInjectedByViewerScript['textsMap'] {
    return {
      addToCartContactSeller: this.translations['wishlist.contactSeller.button'],
      addToCartOutOfStock:
        this.multilingualService.get(PublicDataKeys.OUT_OF_STOCK) || this.translations['wishlist.outOfStock.button'],
      addToCartSuccessSR: this.translations['sr.addToCartSuccess'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
      emptyStateText:
        this.multilingualService.get(PublicDataKeys.NO_PRODUCTS_MESSAGE) || this.translations['wishlist.emptyState'],
      emptyStateLinkText:
        this.multilingualService.get(PublicDataKeys.EMPTY_STATE_LINK) || this.translations['wishlist.CTA'],
      galleryAddToCartButtonText:
        this.multilingualService.get(PublicDataKeys.ADD_TO_CART) || this.translations['wishlist.addToCart.button'],
      loadMoreButton:
        this.multilingualService.get(PublicDataKeys.LOAD_MORE_BUTTON) || this.translations['wishlist.loadMore.button'],
      productOutOfStockText:
        this.multilingualService.get(PublicDataKeys.OUT_OF_STOCK) || this.translations['wishlist.outOfStock.label'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quantityInputSR: this.translations['sr.quantity'],
      quantityAddSR: this.translations['sr.addQty'],
      quantityChooseAmountSR: this.translations['sr.chooseQty'],
      quantityRemoveSR: this.translations['sr.removeQty'],
      quantityMaximumAmountSR: this.translations['wishlist.exceedsQuantity.error'],
      quantityMinimumAmountSR: this.translations['wishlist.minimumQuantity.error'],
      quantityTotalSR: this.translations['sr.totalQty'],
      quickViewButtonText: this.translations['wishlist.quickView.button'],
      wishlistHeaderTitle:
        this.multilingualService.get(PublicDataKeys.TITLE_TEXT) || this.translations['wishlist.title'],
      wishlistHeaderSubtitle:
        this.multilingualService.get(PublicDataKeys.SUBTITLE_TEXT) || this.translations['wishlist.description'],
    };
  }

  private get productsManifest(): ProductsManifest {
    return this.products.reduce((acc, product) => {
      acc[product.id] = {
        url: `${this.sectionUrl}/${product.urlPart}`,
        addToCartState: this.addToCartService.getButtonState({price: actualPrice(product), inStock: product.isInStock}),
      };
      return acc;
    }, {});
  }

  private async handleProductItemClick({biData: {index, productId}}) {
    const product = this.pickProduct(productId);
    const eventData = this.createProductItemBiEventData(productId, index);
    this.reportToBI('clickOnProductBoxSf', eventData);
    this.sendProductClickTrackEvent(product, index);
    await this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      state: this.pickProduct(productId).urlPart,
      queryParams: undefined,
    });
  }

  private async handlePagination(page: number) {
    this.reportToBI('clickLoadMoreInGallerySf', {appName: WISHLIST_BI_APP_NAME, type: 'pagination'});
    this.currentPage = page;
    await this.updateComponent();
  }

  private handleLoadMore() {
    this.reportToBI('clickLoadMoreInGallerySf', {appName: WISHLIST_BI_APP_NAME, type: 'button'});

    const batchSize = this.productsPerPage;

    return async () => {
      this.productsPerPage += batchSize;
      await this.updateComponent();
    };
  }

  private async handleAddToCart({productId, index, quantity}: {productId: string; index: number; quantity: number}) {
    const product = this.pickProduct(productId);
    if (product.options.length || product.customTextFields.length) {
      this.reportClickAddToCartWithOptionsBI(productId, product.productType);
      return this.productActions.quickViewProduct(product.id, ORIGIN, product.urlPart, this.compId, this.externalId);
    }
    this.fedopsLogger.interactionStarted(FedopsEvent.AddToCart);
    this.reportClickAddToCartBI(product, index, quantity);
    this.trackAddToCart(product, quantity);
    await this.siteStore.cartActions.addToCart(productId, [], quantity, [], this.getAddToCartAction(), ORIGIN);
    this.fedopsLogger.interactionEnded(FedopsEvent.AddToCart);
    return this.updateAddToCartStatus(productId, ActionStatus.SUCCESSFUL);
  }

  private handleOpenQuickView({productId, index}: {productId: string; index: number}) {
    const product = this.pickProduct(productId);
    const eventData = {
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: !!product.options.length,
      index,
    };
    this.reportToBI('clickedOnProductQuickViewSf', eventData);
    return this.productActions.quickViewProduct(product.id, ORIGIN, product.urlPart, this.compId, this.externalId);
  }

  private pickProduct(productId: string): IProduct {
    return this.products.find((p) => p.id === productId);
  }

  private async handleClickOnEmptyState(): Promise<void> {
    await this.siteStore.biLogger.clickLinkInMembersWishlistSf({});
    this.siteStore.navigateToLink(this.homepageLink);
  }

  private getPaginationMode(): PaginationTypeName {
    return this.siteStore.isMobile() || this.styleParams.numbers.gallery_paginationFormat === PaginationType.COMPACT
      ? 'compact'
      : 'pages';
  }

  private reportClickAddToCartBI(product: IProduct, index: number, quantity: number) {
    const addToCartAction = this.getAddToCartAction();
    const shouldNavigateToCart = this.siteStore.cartActions.shouldNavigateToCart();

    const eventData = {
      buttonType: BiButtonActionType.AddToCart,
      appName: WISHLIST_BI_APP_NAME,
      index,
      productId: product.id,
      hasOptions: false,
      productType: product.productType,
      origin: ORIGIN,
      isNavigateCart: shouldNavigateToCart,
      navigationClick:
        /* istanbul ignore next: need to fix */
        // eslint-disable-next-line no-nested-ternary
        addToCartAction === AddToCartActionOption.MINI_CART && !shouldNavigateToCart
          ? 'mini-cart'
          : addToCartAction === AddToCartActionOption.CART ||
            (shouldNavigateToCart && addToCartAction !== AddToCartActionOption.NONE)
          ? 'cart'
          : 'none',
      quantity,
    };

    return this.reportToBI('clickOnAddToCartSf', eventData);
  }

  private reportClickAddToCartWithOptionsBI(productId: string, productType: string) {
    const data = {
      appName: WISHLIST_BI_APP_NAME,
      origin: ORIGIN,
      hasOptions: true,
      productId,
      productType,
      navigationClick: this.siteStore.isMobile() ? 'product-page' : 'quick-view',
    };
    this.reportToBI('clickAddToCartWithOptionsSf', data);
  }

  private trackAddToCart(product: IProduct, quantity: number) {
    const params: ITrackEventParams = {
      appDefId: APP_DEFINITION_ID,
      category: 'All Products',
      origin: 'Stores',
      id: product.id,
      name: product.name,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
      quantity,
    };

    this.siteStore.windowApis.trackEvent('AddToCart', params);
  }

  private sendProductClickTrackEvent(product: IProduct, index: number) {
    this.siteStore.windowApis.trackEvent('ClickProduct', {
      appDefId: APP_DEFINITION_ID,
      id: product.id,
      origin: 'Stores',
      name: product.name,
      list: 'Wishlist Gallery',
      category: 'All Products',
      position: index,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
      type: product.productType,
      sku: product.sku,
    });
  }

  private getAddToCartAction(): AddToCartActionOption {
    return this.styleParams.numbers.gallery_addToCartAction;
  }

  /* istanbul ignore next: hard to test it */
  public onAppLoaded(): void {
    if (this.shouldReportFedops) {
      this.fedopsLogger.appLoaded();
      this.shouldReportFedops = false;
    }
  }
}
